<template>
  <standard-modal
    :name="modalName"
    max-width="490px"
    :show-close-button="false"
    :element-test="elementTest + '-confirmation-modal'"
  >
    <template #title>
      <slot name="title" />
    </template>
    <template #content>
      <slot name="content" />
    </template>
    <template #options>
      <slot name="options">
        <slot name="cancel-button">
          <main-button
            :label="$t('actions.cancel')"
            tag="button"
            class-variant="btn-steel"
            :data-test-id="elementTest + '-cancel-button'"
            @click="onClose"
          />
        </slot>
        <slot name="confirm-button">
          <main-button
            :label="$t('actions.yes')"
            tag="button"
            :data-test-id="elementTest + '-confirm-button'"
            @click="onConfirm"
          />
        </slot>
      </slot>
    </template>
  </standard-modal>
</template>

<script>
import StandardModal from '@/components/Modals/StandardModal/StandardModal'
import MainButton from '@/components/Buttons/MainButton/MainButton'

export default {
  name: 'ConfirmationModal',
  components: { MainButton, StandardModal },
  props: {
    modalName: { type: String, default: 'confirmationModal' },
    elementTest: { type: String, default: 'confirmation-modal' },
  },
  emits: ['on-confirm' /*, `modalClose_${this.modalName}`*/],
  methods: {
    onConfirm() {
      this.$emit('on-confirm')
      this.$bus.emit(`modalClose_${this.modalName}`, this.modalName)
    },
    onClose() {
      this.$bus.emit(`modalClose_${this.modalName}`, this.modalName)
    },
  },
}
</script>
